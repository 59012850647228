import React, { useEffect, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";

interface ElongatedTextProps {
  text: string;
  fontSize: string;
}

const ElongatedText: React.FC<ElongatedTextProps> = ({ text, fontSize }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [letterSpacing, setLetterSpacing] = useState<number>(0);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const { offsetWidth } = container;
      const totalTextWidth = offsetWidth / text.length;
      const spacing =
        (offsetWidth - totalTextWidth * text.length) / (text.length - 1);
      setLetterSpacing(spacing);
    }
  }, [text, fontSize]);

  return (
    <Box
      ref={containerRef}
      display="flex"
      justifyContent="space-between"
      fontFamily="monospace"
      fontWeight="semibold"
      fontSize={fontSize}
      letterSpacing={`${letterSpacing}px`}
      whiteSpace="nowrap"
    >
      {text.split("").map((char, index) => (
        <span key={index}>{char}</span>
      ))}
    </Box>
  );
};

export default ElongatedText;
