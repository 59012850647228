import { Box, Divider } from "@chakra-ui/react";
import CMYCubeScene from "../CMYCube/CMYCubeScene";
import { FooterBox } from "../Views/HomeViews/FooterBox";
import ProjectsBox from "../Views/HomeViews/ProjectsBox";
import { Strings } from "../Utils/Strings";
import ElongatedText from "../Views/HomeViews/ElongatedText";

const HomePage = () => {
  return (
    <Box display="flex" flexDirection="column">
      <Box h="100vh" display="flex" flexDirection="column">
        <CMYCubeScene />
        {/* <ProjectsBox /> */}
      </Box>

      <ElongatedText
        text={Strings.GENERAL_STRINGS.KENATZUMU_STUDIO.toLowerCase()}
        fontSize="3vw"
      />
      <Divider borderColor="branding.gray" />

      <ProjectsBox />
      <FooterBox />
    </Box>
  );
};

export default HomePage;
