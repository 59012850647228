import { useEffect, useRef } from "react";
import { Group } from "three";
import gsap from "gsap";

const useCMYCubeScene = () => {
  const meshRef = useRef<Group>(null);
  const animationRef = useRef<gsap.core.Timeline | null>(null);

  const factor = 3;

  const rotationSpeedRef = useRef({
    x: (Math.random() * 0.5 + 0.5) * (Math.random() < 0.5 ? -1 : 1) * factor,
    y: (Math.random() * 0.5 + 0.5) * (Math.random() < 0.5 ? -1 : 1) * factor,
    z: (Math.random() * 0.5 + 0.5) * (Math.random() < 0.5 ? -1 : 1) * factor,
  });

  useEffect(() => {
    if (animationRef.current) {
      animationRef.current.kill();
    }

    const animateCube = () => {
      if (!meshRef.current?.scale) return;

      gsap.set(meshRef.current.scale, { x: 0, y: 0, z: 0 });
      gsap.set(meshRef.current.rotation, { x: 0, y: 0, z: 0 });

      const tl = gsap.timeline();
      animationRef.current = tl;

      const createPopupAnimation = () => {
        return tl.to(meshRef.current!.scale, {
          x: 1,
          y: 1,
          z: 1,
          duration: 3,
          ease: "power4.out",
        });
      };

      const createQuickRotations = () => {
        const fullRotation = Math.PI * 2;
        return gsap.to(meshRef.current!.rotation, {
          y: `+=${fullRotation * 5}`, // 5 full rotations
          duration: 1.5, // Quick 1.5 second duration
          ease: "power2.inOut",
          onComplete: () => {
            if (meshRef.current) {
              // Reset rotation values
              meshRef.current.rotation.x = 0;
              meshRef.current.rotation.y = 0;
              meshRef.current.rotation.z = 0;
              // Restart the main rotation animation
              createRotationAnimation();
            }
          },
        });
      };

      const createRotationAnimation = () => {
        const fullRotation = Math.PI * 2;
        return gsap.to(meshRef.current!.rotation, {
          x: `+=${rotationSpeedRef.current.x * fullRotation}`,
          y: `+=${rotationSpeedRef.current.y * fullRotation}`,
          z: `+=${rotationSpeedRef.current.z * fullRotation}`,
          duration: 60,
          ease: "linear",
          onComplete: () => {
            if (meshRef.current) {
              // When main rotation completes, trigger quick rotations
              createQuickRotations();
            }
          },
        });
      };

      createPopupAnimation();
      createRotationAnimation();
    };

    const initTimer = setTimeout(() => {
      animateCube();
    }, 50);

    return () => {
      clearTimeout(initTimer);
      if (animationRef.current) {
        animationRef.current.kill();
      }
    };
  }, []);

  return {
    meshRef,
  };
};

export default useCMYCubeScene;
