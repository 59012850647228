import React from "react";
import { Box } from "@chakra-ui/react";
import { Canvas } from "@react-three/fiber";
import CMYCubeRender from "./CMYCubeRender";
import useCMYCubeScene from "./useCMYCubeScene";

const CMYCubeScene: React.FC = () => {
  const { meshRef } = useCMYCubeScene();

  return (
    <Box width="100%" height="100vh">
      <Canvas camera={{ position: [0, 0, 8], near: 1, far: 20 }}>
        <group ref={meshRef}>
          <ambientLight intensity={0.5} />
          <CMYCubeRender />
        </group>
      </Canvas>
    </Box>
  );
};

export default CMYCubeScene;
