import { Box, Text } from "@chakra-ui/react";
import { Strings } from "../../Utils/Strings";

export const FooterBox = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-end"
      position="relative"
      minHeight="20vh"
      py="1em"
    >
      <Text color="branding.gray" textAlign="center">
        {Strings.GENERAL_STRINGS.COPYRIGHT}
      </Text>
    </Box>
  );
};
