import { Box, Heading } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Strings } from "../../Utils/Strings";

const ProjectsBox = () => {
  return (
    <Box display="flex" flexDirection="column" px="10vw" pt="2vh">
      <Link to="/experiments">
        <Heading fontWeight="thin">
          {Strings.GENERAL_STRINGS.EXPERIMENTS_STRING.toLowerCase()}
        </Heading>
      </Link>
      {/* <Link to="/kenatzumu-prism">
        <Text>{Strings.KENATZUMU_PRISM_STRINGS.SHORT_PROJECT_NAME}</Text>
      </Link> */}
      {/* <Link to="/daily-sheets">
        <Text>{Strings.DAILY_SHEETS_STRINGS.SHORT_PROJECT_NAME}</Text>
      </Link> */}
    </Box>
  );
};

export default ProjectsBox;
